<template>
    <div class="panel panel-default">
        <div class="panel-heading panel-title">
            <h4>To Do</h4>
        </div>
        <div class="panel-body table-responsive">
            <table class="table">
                <tbody>
                <tr>
                    <th>Scheduled</th>
                    <th>Type</th>
                    <th>Customer</th>
                    <th>Note</th>
                </tr>

                <tr v-for="(task, index) in  data" v-bind:key="index">
                    <td>{{ task.date_range }}</td>
                    <td>{{ task.type }}</td>
                    <td><router-link :to="`/user/${ task.user_id }`">{{ task.user_name }}</router-link></td>
                    <td>{{ task.note }}</td>
                </tr>

                </tbody>
            </table>
        </div>
    </div>

</template>

<script>
export default {
    name: "CRMTodo",
    props: ['data'],
}
</script>

<style scoped>

</style>