<template>
    <div class="panel panel-danger spacing_top details_container">
        <div class="panel-heading">
            <h4 class="panel-title"><span class="glyphicon glyphicon-exclamation-sign"></span> Attention</h4>
        </div>
        <div class="panel-body">
            <div class='spacing_btm'>
                These POs are pending production. Please place your order to complete the process.
            </div>
            <div v-for="unverifiedQuote in this.unverified" v-bind:key="unverifiedQuote.quote_id" class="row spacing_btm">
                <div class="col-sm-3">
                    <div class="object_detail">
                        <label>Quote</label>
                        <div class="form-control"><router-link :to="`/quote/${unverifiedQuote.quote_id}`">{{ unverifiedQuote.quote_id }}</router-link></div>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="object_detail">
                        <label>Job Tag</label>
                        <div class="form-control">{{ unverifiedQuote.job_tag }}</div>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="object_detail">
                        <label>PO</label>
                        <div class="form-control">{{ unverifiedQuote.distributor_po }}</div>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="object_detail">
                        <label>Date</label>
                        <div class="form-control">{{ new Date(unverifiedQuote.quote_date).toLocaleDateString() }}</div>
                    </div>
                </div>
                <div class="col-sm-12">
                    <router-link :to="`/quote/${unverifiedQuote.quote_id}`" class="btn btn-primary">Place Order</router-link>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "UnverifiedQuotes",
    props: ['unverified'],
}
</script>

<style scoped>

</style>