<template>
    <div class="col-lg-12">
        <TurretItems :data="data.batching.data" />
        <EngineeringGoals :data="data.engineering.data" @changeLocation="changeLocation" />
    </div>
</template>

<script>
import { myStore } from '@/store/My.store'
import TurretItems from "@/components/my/dashboards/panels/TurretItems";
import EngineeringGoals from "@/components/my/dashboards/panels/EngineeringGoals";
export default {
    name: 'BatchingDashboard',
    components: {EngineeringGoals, TurretItems},
    data() {
        return {
            data: {
                batching: {},
                engineering: {},
            },
            cdiLocationID: 0,
        }
    },
    methods: {
        getMyData: function() {
            myStore.api('dashboard/get_panels', { 'params[goal_cdi_location_id]': this.cdiLocationID })
                .then( results => this.data = results);
        },
        changeLocation: function(cdiLocationID) {
            this.cdiLocationID = cdiLocationID;
            this.getMyData();
        },

    },
    created() {
        this.getMyData();
    }
}

</script>