<template>
    <div>
        <h3> {{ businessObject }}</h3>
        <div v-if="records && records.length === 0">
            No Recent {{ businessObject }}
        </div>
        <div v-else class="table-responsive">
            <table class="table">
                <tbody>
                <tr>
                    <th>{{ businessObject }}</th>
                    <th>Job{{ businessObject == 'Orders' ? ' / PO' : '' }}</th>
                    <th>Status</th>
                    <th>Date</th>
                </tr>
                <tr v-for="record in records" v-bind:key="record.id">
                    <td><router-link :to="`/${linkTarget}/${record.id}`">{{ record.id }}</router-link></td>
                    <td>{{ record.job }}</td>
                    <td>{{ record.status }}</td>
                    <td>{{ record.key_date }}</td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>
</template>

<script>

export default {
    name: "RecentQuotes",
    props: ['records', 'businessObject'],
    computed: {
        linkTarget: function() {
            let target = 'quote';
            if (this.businessObject === 'Orders') {
                if (this.$appStore.isStaff()) {
                    target = 'tracker';
                }else {
                    target = 'myorder';
                }
            }
            
            return target;
        },
    }
}
</script>

<style scoped>

</style>