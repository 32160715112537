<template>
    <div class="panel panel-default">
        <div class="panel-heading panel-title">
            <h4>Daily Engineering Goals
                <select @change="$emit('changeLocation', cdiLocation)" class="pull-right form-control form_control_inline" v-model="cdiLocation">
                    <option value="0" selected="">All</option>
                    <option v-for="[index, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="index" :value="index">{{ location }}</option>
                </select>
            </h4>
        </div>
        <div class="panel-body table-responsive">
            <table class="table">
                <tbody v-if="data">
                <tr>
                    <th class="right">Day</th>
                    <th class="right">Goal</th>
                    <th class="right">Engineering</th>
                    <th class="right">Need</th>
                    <th class="right">Radan/Batch</th>
                    <th class="right">Need</th>
                    <th class="right">Production</th>
                    <th class="right">Shipped</th>
                </tr>
                <tr v-for="(goal, index) in data.goals" v-bind:key="index">
                    <td class="right">{{ goal.day }}</td>
                    <td class="right">{{ goal.daily_goal }}</td>
                    <td class="right">{{ goal.engineering }}</td>
                    <td class="right">{{ goal.eng_need }}</td>
                    <td class="right">{{ goal.radan_batching }}</td>
                    <td class="right">{{ goal.radan_batching_need }}</td>
                    <td class="right">{{ goal.production }}</td>
                    <td class="right">{{ goal.shipped }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

</template>

<script>
import cdiLocationCache from "@/cache/cdiLocation.cache";
export default {
    name: "EngineeringGoals",
    props: ['data'],
    data() {
        return {
            cdiLocation: 0,
            cache: {
                cdiLocationCache
            }
        }
    }
}
</script>

<style scoped>
    select {
        margin-top: -7px;
    }
</style>