<template>
    <div>
        <div class="col-sm-12">
            <div class="form-group">
                <select class="form-control" v-model="cdi_location_id" @change="getMyData" >
                    <option value="0">CDI</option>
                    <option v-for="[cdiLocationId, cdiLocation] in cache.cdiLocationCache.CDI_LOCATIONS" :key="cdiLocationId" :value="cdiLocationId">{{ cdiLocation }}</option>
                </select>
            </div>
        </div>
        <div class="col-lg-4">
            <Sales :sales="data.incoming" />
        </div>
        <div class="col-lg-4">
            <Sales :sales="data.outgoing" />
        </div>
        <div class="col-lg-4">
            <LinkList :data="data.link_list" />
        </div>
        <div class="col-lg-12">
            <CRMTodo :data="data.crm" />
        </div>
    </div>
</template>

<script>

import { myStore } from '@/store/My.store';
import Sales from "@/components/my/dashboards/panels/Sales";
import LinkList from "@/components/my/dashboards/panels/LinkList";
import CRMTodo from "@/components/my/dashboards/panels/CRMTodo";
import cdiLocationCache from "@/cache/cdiLocation.cache";

export default {
    name: 'SalesDashboard',
    components: {
        CRMTodo,
        LinkList,
        Sales
    },
    data() {
        return {
            data: {
                outgoing: {
                    data: {
                        chart: {},
                    },
                },
                incoming: {
                    data: {
                        chart: {},
                    },
                },
                link_list: {},
                crm: {},
            },
            cdi_location_id: this.$appStore.state.user.defaultLocationID,
            cache: {
                cdiLocationCache
            },
        }
    },
    methods: {
        getMyData: function() {
            myStore.api('dashboard/get_panels', {'params[cdi_location_id]': this.cdi_location_id})
                .then(results => this.data = results);
        }
    },
    created() {
        this.getMyData();
    }
}

</script>
