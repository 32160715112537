<template>
    <div>
        <SideNav />
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter main fill">
            <h1>{{ $appStore.state.user.userName }} <small>{{ $appStore.state.user.companyName }}</small></h1>
            <GApps />
            <div class="row">
                <DefaultDashboard v-if="!$appStore.state.user.dashboard" />
                <SalesDashboard v-if="$appStore.state.user.dashboard === 1" />
                <ProductionDashboard v-if="$appStore.state.user.dashboard === 3" />
                <BatchingDashboard v-if="$appStore.state.user.dashboard === 4" />
                <EngineeringDashboard  v-if="$appStore.state.user.dashboard === 5" />
            </div>
        </main>
    </div>
</template>

<script>
import DefaultDashboard from '@/components/my/dashboards/DefaultDashboard';
import SalesDashboard from '@/components/my/dashboards/SalesDashboard';
import ProductionDashboard from '@/components/my/dashboards/ProductionDashboard';
import BatchingDashboard from '@/components/my/dashboards/BatchingDashboard';
import EngineeringDashboard from '@/components/my/dashboards/EngineeringDashboard';
import SideNav from '@/components/my/MyNav.vue';
import GApps from "@/components/my/GApps";
export default {

    name: 'MyCdi',
    components: {
        GApps,
        DefaultDashboard,
        SalesDashboard,
        ProductionDashboard,
        BatchingDashboard,
        EngineeringDashboard,
        SideNav
    }
}
</script>
