<template>
    <div class="panel panel-default">
        <div class="panel-heading panel-title">
            <h4>Items in Turret</h4>
        </div>
        <div class="panel-body table-responsive">
            <table class="table">
                <tbody>
                <tr>
                    <th class="left">Location</th>
                    <th class="left">Machine</th>
                    <th class="left">Total</th>
                </tr>
                <tr v-for="(turret, index) in data" v-bind:key="index">
                    <td class="left">{{ turret.location }}</td>
                    <td class="left">{{ turret.machine }}</td>
                    <td class="left">{{ turret.num }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

</template>

<script>
export default {
    name: "TurretItems",
    props: ['data'],
}
</script>

<style scoped>

</style>