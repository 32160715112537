<template>
    <div class="col-lg-12">
        <p class="spacing_btm">Need help? Your sales representative is {{ data.sales_rep }} (<a :href="`mailto:${data.sales_email}`">{{ data.sales_email }}</a>)</p>
        <router-link class="btn btn-primary" :to="`/quote/new`">CREATE A QUOTE</router-link>
        <UnverifiedQuotes v-if="data.unverified && data.unverified.length" :unverified="data.unverified" />
        <RecentRecords :records="data.quotes" businessObject="Quotes" />
        <RecentRecords :records="data.orders" businessObject="Orders" />
    </div>
</template>

<script>
import helpers from "@/store/helpers";
import { store } from '@/store/BusinessObject.store';
import UnverifiedQuotes from "@/components/my/dashboards/panels/UnverifiedQuotes";
import RecentRecords from "@/components/my/dashboards/panels/RecentRecords";
import appStore from '@/store/App.store';

export default {
    name: 'DefaultDashboard',
    components: {
        RecentRecords,
        UnverifiedQuotes,
    },
    data() {
        return {
            data: {
                state: store.state,
                appState: appStore.state
            },
        }
    },
    methods: {
        getMyData: function() {
            store.api('dashboard/get_panels', '')
                .then(result => this.data = result);
        },
    },
    computed: {
        showPwa: function () {
            // If the user already has launched our application as a PWA
            if (window.matchMedia('(display-mode: standalone)').matches) {
                return false;
            }
            // The reason we are limiting this to Chrome browsers for now is because of the limited implementation.
            return helpers.getUserBrowser() === 'Google Chrome or Chromium';
        }
    },
    created() {
        store.state.name = '';
        store.state.object.id = 0;
        this.getMyData();
    }
}
</script>

<style scoped>

</style>