<template>
    <div class="panel panel-default">
        <div class="panel-heading panel-title">
            <h4>Links</h4>
        </div>
        <div class="panel-body">
            <ul>
                <li v-for="(link, index) in data.links" v-bind:key="index">
                    <a :href="link.link" target="_blank">{{ link.title }}</a>
                </li>
            </ul>
        </div>
    </div>

</template>

<script>
export default {
    name: "LinkList",
    props: ['data'],
}
</script>

<style scoped>

</style>