<template>
    <div class="panel panel-default">
        <div class="panel-heading panel-title">
            <h4>On Time Ship
                <select v-model="cdiLocation" @change="$emit('changeLocation', cdiLocation)" class="pull-right form-control form_control_inline">
                    <option value="0" selected="">All</option>
                    <option v-for="[index, location] in cache.cdiLocationCache.CDI_LOCATIONS" :value="index"  v-bind:key="index" >{{ location }}</option>
                </select>
            </h4>
        </div>
        <div class="panel-body">
            <div v-for="(metric, index) in onTime" v-bind:key="index">
                <div>
                    <span :class="`dashboard_lg_text ${(parseFloat(metric.value) > parseFloat(metric.goal)) ? '' : 'text-danger'}`">{{ metric.value }}</span> / <span>{{ metric.goal }}% <small></small></span>&nbsp;
                </div>
                <label>{{ metric.label }}</label>
            </div>
        </div>
    </div>

</template>

<script>
import cdiLocationCache from '@/cache/cdiLocation.cache';
export default {
    name: "OnTimeShip",
    props: ['onTime'],
    data() {
        return {
            cdiLocation: 0,
            cache: {
                cdiLocationCache
            }
        }
    }
}
</script>

<style scoped>
    select {
        margin-top: -7px;
    }
</style>