import { render, staticRenderFns } from "./DefaultDashboard.vue?vue&type=template&id=d8480f12&scoped=true&"
import script from "./DefaultDashboard.vue?vue&type=script&lang=js&"
export * from "./DefaultDashboard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8480f12",
  null
  
)

export default component.exports