<template>
    <div>
        <div class="col-lg-4">
            <OnTimeShip :onTime="data.monthly_on_time" @changeLocation="changeOnTimeLocation" />
        </div>
        <div class="col-lg-12">
            <RevenueShipped :shipped="data.monthly_shipped" @changeLocation="changeShippedLocation" />
        </div>
    </div>
</template>

<script>
import { myStore } from '@/store/My.store';
import OnTimeShip from "@/components/my/dashboards/panels/OnTimeShip";
import RevenueShipped from "@/components/my/dashboards/panels/RevenueShipped";

export default {
    name: 'ProductionDashboard',
    components: {
        RevenueShipped,
        OnTimeShip
    },
    data() {
        return {
            onTimeCdiLocation: 0,
            shippedCdiLocation: 0,
            data: {},
        }
    },
    methods: {
        getMyData: function() {
            myStore.api('dashboard/get_panels', {
                'params[ontime_cdi_location_id]': this.onTimeCdiLocation,
                'params[shipped_cdi_location_id]': this.shippedCdiLocation
            }).then(data => this.data = data);
        },
        changeOnTimeLocation: function(cdiLocation) {
            this.onTimeCdiLocation = cdiLocation;
            this.getMyData();
        },
        changeShippedLocation: function(cdiLocation) {
            this.shippedCdiLocation = cdiLocation;
            this.getMyData();
        },
    },
    created() {
        this.getMyData();
    }
}

</script>