<template>
<div >
    <div class="col-sm-12" v-if="isLive && isGappUser && !hasGAppToken">
        <div class="alert alert-danger">
            <p><strong>Your Google Account is not Linked</strong></p>
            <p>Any services that require a connection to Google such as website generated email, CRM events, or saving to Drive will not work until this link has been established.</p>
            <button @click="connectGoogle" class="alert-link pointer">Click here to connect to Google</button>
        </div>
    </div>
</div>
</template>

<script>

import { store } from '@/store/Tool.store';

export default {
    name: 'GApps',
    methods: {
        connectGoogle: function () {
            let params = {
                'params[user_id]': this.$appStore.state.user.userID
            };
            store.api('/mycdi/get_gapp_auth_url/', params).then((authUrl) => {
                window.location.href = authUrl;
            });

        },
        authenticateToken: function (token) {
            let params = {
                'params[user_id]': this.$appStore.state.user.userID,
                'params[token]': token
            };
            let scope = this;
            store.api('/mycdi/authenticate_gapp_token/', params).then(result => {
                if (typeof result !== "undefined" && result.opt === 'msg') {
                    scope.$appStore.warningBox('Your token has been retrieved. Please sign out and back into the website to complete activation.');
                }
            });
        }
    },
    computed: {
        isLive: function () {
            return this.$appStore.state.loggedIn;
        },
        hasGAppToken: function () {
            return this.$appStore.state.user.hasGAppsToken;
        },
        isGappUser: function() {
            return this.$appStore.state.user.hasGApps
        }
    },
    created: function () {
        let urlToken = this.$route.query.code;
        if (urlToken) {
            this.authenticateToken(urlToken);
        }
    }
}

</script>