<template>
    <div class="panel panel-default">
        <div class="panel-heading panel-title">
            <h4>Revenue
                <select v-model="cdiLocation" @change="$emit('changeLocation', cdiLocation)" class="pull-right form-control form_control_inline">
                    <option value="0" selected="">All</option>
                    <option v-for="[index, location] in cache.cdiLocationCache.CDI_LOCATIONS" :value="index"  v-bind:key="index" >{{ location }}</option>
                </select>
            </h4>
        </div>
        <div class="panel-body table-responsive">
            <table class="table">
                <tbody>
                <tr>
                    <th class="right"></th>
                    <th class="right">Shipped (+/-)</th>
                    <th class="right">Scheduled to Ship</th>
                    <th class="right">Goal</th>
                </tr>
                <tr v-for="(metric, index) in shipped" v-bind:key="index">
                    <td class="right">{{ metric.timeframe }}</td>
                    <td class="right">${{ metric.total }} (<span :class="metric.class" >{{ metric.shipped }}</span>)</td>
                    <td class="right">${{ metric.scheduled }}</td>
                    <td class="right">${{ metric.goal }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

</template>

<script>
import cdiLocationCache from "@/cache/cdiLocation.cache";

export default {
    name: "RevenueShipped",
    props: ['shipped'],
    data() {
        return {
            cdiLocation: 0,
            cache: {
                cdiLocationCache
            }
        }
    }
}
</script>

<style scoped>
select {
    margin-top: -7px;
}
</style>